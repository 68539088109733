/**
 * 常量值
 */

/**
 * 表格分页配置
 */
export const PAGINATION = {
    current: 1,     // 当前页数
    pageSize: 10,               // 每页条数
    total: 0,           // 数据总数
    showSizeChanger: true,           // 是否展示 pageSize 切换器，当 total 大于 50 时默认为 true
    showQuickJumper: true,          // 是否可以快速跳转至某页
    showTotal: total => `共 ${total} 条记录` // 用于显示数据总量和当前数据顺序

}


/**
 * 天地图 key
 */
export const KEY = 'd979f223ea462aaa6ee630f890ebd75b'
// export const KEY = 'aa4aa1e0292672223e7df47171728b0f'

/**
 * 天地图影像底图-url
 */
export const TILELAYER_IMG_URL = `http://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=${KEY}`;

/**
 * 天地图标注-url
 */
export const TILELAYER_CIA_URL = `http://t{s}.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=${KEY}`;

// 地图参数
export const SUBDOMAINS = [0, 1, 2, 3, 4, 5, 6, 7]

// 地图默认配置
export const MAPOPTIONS = {
    minZoom: 2,
    maxZoom: 30,
    attributionControl: false,
    zoomControl: false, // 默认情况下，是否将 zoom 缩放控件添加到地图中。
}

// 绘制多边形配置
export const PMDRAWPARAMS = {
    position: "topright",
    drawMarker: false, //   添加一个绘制marker点按钮
    drawCircleMarker: false, // 添加一个绘制圆型marker点按钮
    drawPolyline: false, //添加按钮绘制线条
    drawRectangle: false, //添加按钮绘制矩形
    drawCircle: false, //  添加按钮绘制圆圈
    pinningOption: false,// 添加一个切换钉点选项按钮
    snapingOption: false, //添加一个切换捕捉选项按钮
    drawText: false,        // 添加一个文本
    drawPolygon: false, // 新增操作时添加绘制多边形按钮可用
    editMode: false, //  添加按钮编辑多边形
    dragMode: false, //   添加按钮拖动多边形
    cutPolygon: false, // 添加一个按钮以删除图层里面的部分内容
    removalMode: false, // 清除图层
    rotateMode: false // 旋转模式
}