export const withParams = (Component, useParams = () => { }, useLocation = () => { }) => {
    return props => <Component {...props} params={useParams()} location={useLocation()} />
}

/**
 * @description 进行延时，以达到可以简写代码的目的 比如: await uni.$u.sleep(20)将会阻塞20ms
 * @param {number} value 堵塞时间 单位秒
 * @returns {Promise} 返回promise
 */
export const sleep = (seconds = 1) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, 1000 * seconds)
    })
}


// 格式化绘制图形坐标组
export const latLngFormat = (value) => {
    const data = object(value) ? [value.lat, value.lng] : value.map(item => {
        return item.lat ? [item.lat, item.lng] : latLngFormat(item)
    })
    return data
}

// 获取绘制图形坐标
export const getLatLng = (target) => {
    const LatLng = target.getLatLngs ? target.getLatLngs() : target.getLatLng()
    return latLngFormat(LatLng)
}

/**
 * 验证电子邮箱格式
 */
export function email(value) {
    return /^\w+((-\w+)|(\.\w+))*[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value)
    // return /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value)
}

/**
 * 验证手机格式
 */
export function mobile(value) {
    return /^1([3589]\d|4[5-9]|6[1-2,4-7]|7[0-8])\d{8}$/.test(value)
}


/**
 * 验证整数
 */
export function digits(value) {
    return /^\d+$/.test(value)
}

/**
 * 验证身份证号码
 */
export function idCard(value) {
    return /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
        value
    )
}


/**
 * 是否车牌号
 */
export function carNo(value) {
    // 新能源车牌
    const xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/
    // 旧车牌
    const creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/
    if (value.length === 7) {
        return creg.test(value)
    } if (value.length === 8) {
        return xreg.test(value)
    }
    return false
}

/**
 * 金额,只允许2位小数
 */
export function amount(value) {
    // 金额，只允许保留两位小数
    return /^[1-9]\d*(,\d{3})*(\.\d{1,2})?$|^0\.\d{1,2}$/.test(value)
}

/**
 * 中文
 */
export function chinese(value) {
    const reg = /^[\u4e00-\u9fa5]+$/gi
    return reg.test(value)
}


/**
 * 判断是否为空
 */
export function isEmpty(value) {
    switch (typeof value) {
        case 'undefined':
            return true
        case 'string':
            if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length === 0) return true
            break
        case 'boolean':
            if (!value) return true
            break
        case 'number':
            if (value === 0 || isNaN(value)) return true
            break
        case 'object':
            if (value === null || value.length === 0) return true
            for (const i in value) {
                return false
            }

            return true
        default:
    }
    return false
}



/**
 * 是否数组
 */
export function array(value) {
    if (typeof Array.isArray === 'function') {
        return Array.isArray(value)
    }
    return Object.prototype.toString.call(value) === '[object Array]'
}

/**
 * 是否对象
 */
export function object(value) {
    return Object.prototype.toString.call(value) === '[object Object]'
}



/**
 * 计算多边形的面积 亩
 * @param {*} coords 
 * @returns 
 */
export const ringArea = function ringArea(coords) {
    var RADIUS = 6378137;
    var rad = function rad(_) {
        return _ * Math.PI / 180;
    };
    var p1, p2, p3, lowerIndex, middleIndex, upperIndex,
        area = 0,
        coordsLength = coords.length;

    if (coordsLength > 2) {
        for (var i = 0; i < coordsLength; i++) {
            if (i === coordsLength - 2) {// i = N-2
                lowerIndex = coordsLength - 2;
                middleIndex = coordsLength - 1;
                upperIndex = 0;
            } else if (i === coordsLength - 1) {// i = N-1
                lowerIndex = coordsLength - 1;
                middleIndex = 0;
                upperIndex = 1;
            } else { // i = 0 to N-3
                lowerIndex = i;
                middleIndex = i + 1;
                upperIndex = i + 2;
            }
            p1 = coords[lowerIndex];
            p2 = coords[middleIndex];
            p3 = coords[upperIndex];
            area += (rad(p3.lng) - rad(p1.lng)) * Math.sin(rad(p2.lat));
        }

        area = area * RADIUS * RADIUS / 2;
    }

    return (Math.abs(area) * 0.0015).toFixed(2);
};

/**
 * 下载excel
 */
export function downloadFile(data, filename, suffix = 'xlsx') {
    const blob = new Blob([data])
    const downloadElement = document.createElement('a');
    const href = window.URL.createObjectURL(blob);  //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = `${filename}.${suffix}`;  //下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);     //下载完成移除元素
    window.URL.revokeObjectURL(href);               //释放掉blob对象
}