import React, { Component } from 'react';
import { Card, Space, Tooltip, Button, Table, Divider, DatePicker, Select, Upload } from 'antd';
import { Loading3QuartersOutlined, ExportOutlined, CloudUploadOutlined,CheckOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../store/context';
import DropdownColumns from '../../../../components/dropdown-columns';
import dayjs from 'dayjs';
import { PAGINATION } from '../../../../utils/constants';
import { sys } from '../../../../service';

function regexHandleNum(num) {
    return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ' ,'); // 3是千分位，4是万分位
}


export default class SaleDayView extends Component {
    static contextType = AppContext
    state = {
        queryParams: {
            start_day: dayjs(),
            end_day: dayjs(),
            store: null
        },
        storeTree: [],
        columnsKey: ['store', 'store_name', 'pos_no', 'close_time', 'bill_cnt', 'cust_cnt',
            'sale_tot', 'tax', 'sale_amt', 'card_amt', 'cash_amt', 'gift_amt',
            'cancel_tot', 'cash_loss', 'gift_loss', 'alipay', 'wechat'],
        columns: [
            {
                title: '店铺ID',
                dataIndex: 'store'
            },
            {
                title: '店铺名称',
                dataIndex: 'store_name'
            },
            {
                title: 'POS机编号',
                dataIndex: 'pos_no'
            },
            {
                title: '结算时间',
                dataIndex: 'close_time',
                render: v => dayjs(v).format('YYYY-MM-DD')
            },
            {
                title: '单据数量',
                dataIndex: 'bill_cnt'
            },
            {
                title: '客人数量',
                dataIndex: 'cust_cnt'
            },
            {
                title: '销售总额',
                dataIndex: 'sale_tot',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '附加税',
                dataIndex: 'tax',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '纯销售额',
                dataIndex: 'sale_amt',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '卡结金额',
                dataIndex: 'card_amt',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '现金金额',
                dataIndex: 'cash_amt',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '购物券结算金额',
                dataIndex: 'gift_amt',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '折扣金额',
                dataIndex: 'cancel_tot',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '现金短缺',
                dataIndex: 'cash_loss',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '礼品券短缺',
                dataIndex: 'gift_loss',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '支付宝金额',
                dataIndex: 'alipay',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '微信金额',
                dataIndex: 'wechat',
                render: (v) => regexHandleNum(v)
            }
        ],
        dataSource: [],
        id: null,
        open: false,
        loading: false,
        pagination: PAGINATION
    }



    // 首次加载数据
    componentDidMount() {
        this.getStoreList()
        this.fetch()
    }

    getStoreList = async () => {
        try {
            const { data } = await sys.apiUser.getStoreList()
            this.setState({
                storeTree: data.map(record => ({ value: record.username, label: record.name }))
            })
        } catch (error) {

        }
    }

    // 获取列表数据
    fetch = async (params = {  pagination: PAGINATION }) => {
        this.setState({ loading: true })
        const  { current, pageSize } = params.pagination
            , { queryParams: { start_day, end_day, store } } = this.state
            , { data:{total, records} } = await sys.getSale({ store, start_day: dayjs(start_day).format('YYYY-MM-DD'), end_day: dayjs(end_day).format('YYYY-MM-DD'),current, pageSize })
        this.setState({
            loading: false,
            dataSource: records,
            pagination: {
                ...PAGINATION,
                current,
                pageSize,
                total: total
            }
        })
    }

    // 导出
    handleExport = async () => {
        const { start_day, end_day, store } = this.state.queryParams
        await sys.apiUser.daydown(`/v2/sale/daily/down?store=${store ? store : ''}&start_day=${dayjs(start_day).format('YYYY-MM-DD')}&end_day=${dayjs(end_day).format('YYYY-MM-DD')}`)
    }

    render() {
        const { dataSource, columns, loading, columnsKey, storeTree, queryParams: { start_day, end_day }, pagination } = this.state
        const { fetch } = this
        return (
            <>
                <Card
                    title={"日结列表"}
                    className='page-card-full'
                    extra={
                        <Space
                            split={<Divider type="vertical" />}
                        >
                            <Select
                                options={storeTree}
                                style={{ width: 300 }}
                                placeholder="请选择门店"
                                onChange={(value) => {
                                    this.setState({
                                        queryParams: {
                                            ...this.state.queryParams,
                                            store: value
                                        }
                                    }, () => {
                                        fetch()
                                    })
                                }}
                            >

                            </Select>
                            <DatePicker.RangePicker
                                defaultValue={[start_day, end_day]}
                                allowClear={false}
                                onChange={([start_day, end_day]) => {
                                    this.setState({
                                        queryParams: {
                                            ...this.state.queryParams,
                                            start_day,
                                            end_day
                                        }
                                    })
                                }}
                            />

                            <Button
                                icon={<CheckOutlined />}
                                onClick={() => {
                                    fetch()
                                }}
                            >
                                查询
                            </Button>

                            <Tooltip title='刷新'>
                                <Button
                                    type='text'
                                    icon={<Loading3QuartersOutlined />}
                                    onClick={() => fetch()}
                                />
                            </Tooltip>

                            <DropdownColumns
                                columns={columns}
                                checkedKeys={columnsKey}
                                onChange={columnsKey => this.setState({ columnsKey })}
                            />
                            <Upload
                                beforeUpload={async file => {
                                    const { code, message } = await sys.apiUser.import(file)
                                    // 导入成功
                                    if (code === 0) {
                                        this.context.notification.success({
                                            message: '提示',
                                            placement: 'bottomRight',
                                            description: message
                                        })
                                    } else {
                                        this.context.notification.error({
                                            message: '提示',
                                            placement: 'bottomRight',
                                            description: message
                                        })
                                    }
                                    return false
                                }}
                                maxCount={1}
                                name="files"
                                showUploadList={false}
                            >   
                                <Button
                                    icon={<CloudUploadOutlined />}
                                    type='text'
                                >
                                    上传营业数据
                                </Button>
                            </Upload>
                            <Button
                                icon={<ExportOutlined />}
                                onClick={this.handleExport}
                                type="text"
                            >
                                导出
                            </Button>
                        </Space>
                    }
                >
                    <Table
                        columns={columns.filter(record => columnsKey.includes(record.dataIndex))}
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={(pagination) => fetch({ pagination })}
                        loading={loading}
                        virtual
                        scroll={{  y: window.innerHeight - 310}}
                    />
                </Card>
            </>
        )
    }
}