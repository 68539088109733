import React, { Component } from 'react';
import { Card, Space, Tooltip, Button, Table, Divider, DatePicker, Select } from 'antd';
import { Loading3QuartersOutlined, ExportOutlined, CheckOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../store/context';
import DropdownColumns from '../../../../components/dropdown-columns';
import dayjs from 'dayjs';
import { PAGINATION } from '../../../../utils/constants';
import { sys } from '../../../../service';

function regexHandleNum(num) {
    return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ' ,'); // 3是千分位，4是万分位
}


export default class GoodsSaleDayView extends Component {
    static contextType = AppContext
    state = {
        queryParams: {
            start_day: dayjs(),
            end_day: dayjs(),
            store: null
        },
        storeTree: [],
        columnsKey: ['id', 'city', 'principal', 'store_name', 'day', 'erp', 'count', 'discount',
            'received', 'proportion', 'classify', 'ip', 'type', 'structure', 'subdivide', 'price',
            'goods_name'],
        columns: [
            {
                title: '序号',
                dataIndex: 'id'
            },
            {
                title: '城市',
                dataIndex: 'city'
            },
            {
                title: '客户',
                dataIndex: 'principal'
            },
            {
                title: '门店名称',
                dataIndex: 'store_name'
            },
            {
                title: '营业日期',
                dataIndex: 'day'
            },
            // {
            //     title: '结算时间',
            //     dataIndex: 'close_time',
            //     render: v => dayjs(v).format('YYYY-MM-DD')
            // },
            {
                title: 'ERP 编码',
                dataIndex: 'erp'
            },
            {
                title: '数量',
                dataIndex: 'count'
            },
            {
                title: '折扣优惠',
                dataIndex: 'discount'
            },
            {
                title: '销售',
                dataIndex: 'received',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '占比',
                dataIndex: 'proportion',
                // render: (v) => regexHandleNum(v)
            },
            {
                title: '大分类',
                dataIndex: 'classify',
                // render: (v) => regexHandleNum(v)
            },
            {
                title: 'IP',
                dataIndex: 'ip',
                // render: (v) => regexHandleNum(v)
            },
            {
                title: '类型',
                dataIndex: 'type',
                // render: (v) => regexHandleNum(v)
            },
            {
                title: '结构',
                dataIndex: 'structure',
                // render: (v) => regexHandleNum(v)
            },
            {
                title: '细分',
                dataIndex: 'subdivide',
                // render: (v) => regexHandleNum(v)
            },
            {
                title: '零售价',
                dataIndex: 'price',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '品名',
                dataIndex: 'goods_name',
                // render: (v) => regexHandleNum(v)
            },
            // {
            //     title: '现金短缺',
            //     dataIndex: 'cash_loss',
            //     render: (v) => regexHandleNum(v)
            // },
            // {
            //     title: '礼品券短缺',
            //     dataIndex: 'gift_loss',
            //     render: (v) => regexHandleNum(v)
            // },
            // {
            //     title: '支付宝金额',
            //     dataIndex: 'alipay',
            //     render: (v) => regexHandleNum(v)
            // },
            // {
            //     title: '微信金额',
            //     dataIndex: 'wechat',
            //     render: (v) => regexHandleNum(v)
            // }
        ],
        dataSource: [],
        id: null,
        open: false,
        loading: false,
        pagination: PAGINATION
    }



    // 首次加载数据
    componentDidMount() {
        this.getStoreList()
        this.fetch()
    }

    getStoreList = async () => {
        try {
            const { data } = await sys.apiUser.getStoreList()
            this.setState({
                storeTree: data.map(record => ({ value: record.username, label: record.name }))
            })
        } catch (error) {

        }
    }

    // 获取列表数据
    fetch = async (params = {  pagination: PAGINATION }) => {
        this.setState({ loading: true })
        const { current, pageSize } = params.pagination
            , { queryParams: { start_day, end_day, store } } = this.state
            , { data:{total, records} } = await sys.getGoodsSale({ store, start_day: dayjs(start_day).format('YYYY-MM-DD'), end_day: dayjs(end_day).format('YYYY-MM-DD'),current, pageSize })
        this.setState({
            loading: false,
            dataSource: records,
            pagination: {
                ...PAGINATION,
                current,
                pageSize,
                total: total
            }
        })
    }

    // 导出商品销售列表
    handleExport = async () => {
        const { start_day, end_day, store } = this.state.queryParams
        await sys.apiUser.daydown(`/v2/sale/goods/down?store=${store ? store : ''}&start_day=${dayjs(start_day).format('YYYY-MM-DD')}&end_day=${dayjs(end_day).format('YYYY-MM-DD')}`)
    }

    // 导出商品销售列表
    handleExportSku = async () => {
        await sys.apiUser.daydown(`/v2/sale/goods/sku`)
    }
    render() {
        const { dataSource, columns, loading, columnsKey, storeTree, queryParams: { start_day, end_day } , pagination} = this.state
        const { fetch } = this
        return (
            <>
                <Card
                    title={"商品销售"}
                    className='page-card-full'
                    extra={
                        <Space
                            split={<Divider type="vertical" />}
                        >
                            <Select
                                options={storeTree}
                                style={{ width: 300 }}
                                placeholder="请选择门店"
                                onChange={(value) => {
                                    this.setState({
                                        queryParams: {
                                            ...this.state.queryParams,
                                            store: value
                                        }
                                    }, () => {
                                        fetch()
                                    })
                                }}
                            >

                            </Select>
                            <DatePicker.RangePicker
                                defaultValue={[start_day, end_day]}
                                allowClear={false}
                                onChange={([start_day, end_day]) => {
                                    this.setState({
                                        queryParams: {
                                            ...this.state.queryParams,
                                            start_day,
                                            end_day
                                        }
                                    })
                                }}
                            />

                            <Button
                                icon={<CheckOutlined />}
                                onClick={() => {
                                    fetch()
                                }}
                            >
                                查询
                            </Button>

                            <Tooltip title='刷新'>
                                <Button
                                    type='text'
                                    icon={<Loading3QuartersOutlined />}
                                    onClick={() => fetch()}
                                />
                            </Tooltip>

                            <DropdownColumns
                                columns={columns}
                                checkedKeys={columnsKey}
                                onChange={columnsKey => this.setState({ columnsKey })}
                            />
                            <Button
                                icon={<ExportOutlined />}
                                onClick={this.handleExport}
                                type="text"
                            >
                                导出商品销售列表
                            </Button>
                            <Button
                                icon={<ExportOutlined />}
                                onClick={this.handleExportSku}
                                type="text"
                            >
                                导出门店SKU列表
                            </Button>
                        </Space>
                    }
                >
                    <Table
                        columns={columns.filter(record => columnsKey.includes(record.dataIndex))}
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={(pagination) => fetch({ pagination })}
                        loading={loading}
                        virtual
                        scroll={{  y: window.innerHeight - 310}}
                    />
                </Card>
            </>
        )
    }
}