import React, { useContext, useEffect, useState } from 'react';
import { Layout, Form, Input, Button, Checkbox, Divider, Typography, ConfigProvider, theme, Space } from 'antd';
import { useNavigate } from "react-router-dom";
import { UserOutlined, LockOutlined, CopyrightOutlined } from '@ant-design/icons';
import { AppContext, useAppContextAtom } from '../../store/context';
import { useDispatch } from 'react-redux';
import { setToken } from '../../store/modules/token';
import { sys } from '../../service';
import './index.scss';
// import applet from './image/applet_code.jpg'
const { Content, Footer } = Layout;
export default function Login() {
    const dispatch = useDispatch()
        , navigate = useNavigate()
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , [, setApppContext] = useAppContextAtom()
        , { notification } = useContext(AppContext)
        , [form] = Form.useForm()

    useEffect(() => {
        const { username, password } = JSON.parse(localStorage.getItem('remember')) || { username: null, password: null }
        form.setFieldsValue({
            username,
            password
        })
    }, [form])

    // 提交登录
    const onFinish = async ({ username, password, remember }) => {
        setLoading(true)
        sys.userLogin(username, password).then(async ({ data }) => {
            const { token } = data
            // , token = `Bearer ${access_token}`
            // , result = await sys.getUserMenu(userInfo.uid, token)
            dispatch(setToken({ token }))
            setApppContext((state) => {
                return {
                    ...state,
                    userMenu: [
                        // {
                        //     id: "1",
                        //     name: "营业数据上报",
                        //     url: "/daysale/upload",
                        //     type: 2,
                        //     icon: "PieChartOutlined",
                        // },
                        {
                            id: "2",
                            name: "日报查询",
                            url: "/daysale/sale-day",
                            type: 2,
                            icon: "PieChartOutlined",
                        },
                        {
                            id: "3",
                            name: "日结查询",
                            url: "/daysale/sale",
                            type: 2,
                            icon: "PieChartOutlined",
                        },
                        {
                            id: "4",
                            name: "商品销售查询",
                            url: "/daysale/goods",
                            type: 2,
                            icon: "PieChartOutlined",
                        }
                    ],
                }
            })
            setLoading(false)
            notification.success({
                message: '提示',
                placement: 'bottomRight',
                description: '登录系统成功！'
            })
            navigate('/')
            if (remember) {
                localStorage.setItem('remember', JSON.stringify({
                    username,
                    password
                }))
            } else {
                localStorage.removeItem('remember')
            }
        }).catch((e) => {
            setLoading(false)
            notification.error({
                message: '提示',
                placement: 'bottomRight',
                description: e.message
            })
        })
    }

    return (
        <>
            <ConfigProvider
                theme={{
                    algorithm: theme.defaultAlgorithm,
                }}
            >
                <Layout className="layout">
                    <Content className='flex middle'>
                        <Typography.Title style={{ fontSize: '48px', letterSpacing: '2px', color: '#005F35', position: 'absolute', top: '5vh' }} >黄山诺曼电子科技有限公司</Typography.Title>

                        <Form
                            form={form}
                            name="basic"
                            initialValues={{ username: null, password: null, remember: true }}
                            onFinish={onFinish}
                            className="login-form"
                        >
                            <Typography.Title style={{ color: '#005F35', marginBottom: 30 }} level={2}>登录</Typography.Title>

                            <Form.Item name="username" rules={[{ required: true, message: '请输入登录账号' }]}>
                                <Input
                                    prefix={<UserOutlined />}
                                    placeholder="请输入登录账号"
                                    autoComplete="no"
                                />
                            </Form.Item>

                            <Form.Item name="password" rules={[{ required: true, message: '请输入登录密码' }]}>
                                <Input.Password
                                    prefix={<LockOutlined />}
                                    placeholder="请输入登录密码"
                                    autoComplete="new-password"
                                />
                            </Form.Item>

                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>记住密码</Checkbox>
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    size="large"
                                    type="primary"
                                    className="login-form-button"
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    登录
                                </Button>
                            </Form.Item>

                            
                        </Form>
                    </Content>
                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="parallax">
                            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                        </g>
                    </svg>
                    <Footer style={{ display: 'flex', justifyContent: 'center', background: '#fff' }}>
                        <Space
                            split={<Divider type="vertical" />}
                        >
                            <CopyrightOutlined />
                            黄山诺曼电子科技有限公司
                            <a href="https://beian.miit.gov.cn/"  target="_blank">皖ICP备2023021515号-1</a>

                        </Space>
                    </Footer>
                </Layout>
            </ConfigProvider>
        </>
    )
}