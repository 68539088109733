import React, { useContext, useState } from 'react';
import { ConfigProvider, Layout, Space, theme } from 'antd';
import { AppContext, useAppContextAtom } from '../../store/context';
import RouteView from './route';
import Menu from './menu';
import Logo from './logo';
import Tool from './tool';
import Collapsed from './collapsed';
import SetTheme from './theme';
import PageBreadcrumb from '../../components/breadcrumb';

const { useToken } = theme;
export default function Admin() {
    const { collapsed, theme: { skin, layout, contentTheme } } = useContext(AppContext)
        , { token } = useToken()
        , [, setApppContext] = useAppContextAtom()
        , [openTheme, setTheme] = useState(false)
    
    return (
        <>
            <Layout
                style={{ width: '100vw', height: '100vh' }}
            >
                {
                    layout === 'sider' ? (
                        <Layout.Sider
                            breakpoint="lg"
                            collapsedWidth="64"
                            collapsed={collapsed}
                            style={{
                                backgroundColor: token.colorBgContainer,
                                boxShadow: token.boxShadowDrawerRight,
                                borderRight: `1px solid ${token.colorBorderSecondary}`
                            }}
                        >
                            <div
                                style={{
                                    height: '100vh',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Logo />

                                <div style={{ flexGrow: 2 }}>
                                    <Menu
                                        mode="inline"
                                    />
                                </div>
                                <div>
                                    <Collapsed />
                                </div>
                            </div>
                        </Layout.Sider>
                    ) : (
                        <Layout.Header
                            style={{
                                backgroundColor: token.colorBgContainer,
                                padding: '0 20px',
                                zIndex: 10,
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderBottom: contentTheme === 'light' && skin === 'dark' ? 'none' : `1px solid ${token.colorBorderSecondary}`
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Logo />
                                {
                                    layout === 'top' ? (
                                        <div>
                                            <Menu
                                                mode="horizontal"
                                            />
                                        </div>
                                    ) : (
                                        <div style={{ flexGrow: 2, marginLeft: 20 }}>
                                            <PageBreadcrumb type='breadcrumb'/>
                                        </div>
                                    )
                                }
                            </div>
                            <Tool
                                setTheme={setTheme}
                            />
                        </Layout.Header>
                    )
                }

                <Layout>
                    {
                        layout === 'sider' ? (
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorBgBase: skin === 'dark' && contentTheme === 'light' ? '#FFFFFF' : token.colorBgBase,
                                        colorTextBase: skin === 'dark' && contentTheme === 'light' ? '#000000' : token.colorTextBase
                                    },
                                }}
                            >
                                <Layout.Header
                                    style={{
                                        backgroundColor: skin === 'dark' && contentTheme === 'light' ? 'white' : token.colorBgContainer,
                                        borderBottom: skin === 'dark' && contentTheme === 'light' ? '1px solid #F0F0F0' : `1px solid ${token.colorBorderSecondary}`,
                                        padding: '0 20px',
                                        justifyContent: 'space-between',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div style={{ flexGrow: 2 }}>
                                        <PageBreadcrumb type='breadcrumb' />
                                    </div>
                                    <Tool
                                        setTheme={setTheme}
                                    />
                                </Layout.Header>
                            </ConfigProvider>
                        ) : null
                    }
                    {
                        layout === 'blend' ? (
                            <Layout.Sider
                                breakpoint="lg"
                                collapsedWidth="64"
                                collapsed={collapsed}
                                style={{
                                    backgroundColor: token.colorBgContainer,
                                    borderRight: `1px solid ${token.colorBorderSecondary}`,
                                    minHeight: 'calc(100vh - 64px)'
                                }}
                                onBreakpoint={(collapsed) => {
                                    setApppContext((state) => {
                                        return {
                                            ...state,
                                            collapsed
                                        }
                                    })
                                }}
                            >
                                <Space
                                    style={{
                                        width: '100%',
                                        height: 'calc(100% - 49px)',
                                        display: 'block'
                                    }}
                                >
                                    <Menu
                                        mode="inline"
                                    />
                                </Space>
                                <Collapsed />
                            </Layout.Sider>
                        ) : null
                    }
                    <ConfigProvider
                        theme={{
                            algorithm: contentTheme === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm
                        }}
                    >
                        <Layout.Content
                            style={{
                                backgroundColor: contentTheme === 'light' ? 'white' : '#141414',
                                overflowY: 'auto'
                            }}
                            className={`content-${skin}`}
                        >
                            <RouteView />
                        </Layout.Content>
                    </ConfigProvider>
                </Layout>
            </Layout>

            <SetTheme
                open={openTheme}
                onClose={() => setTheme(false)}
            />
        </>
    )
}