import React, { useContext, useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom';
import { AppContext } from '../../store/context';
import Home from '../main/home';

// 营业数据上报
import DaysaleUploadView from '../main/daysale/upload';
import SaleDayView from '../main/daysale/sale-day';
import SaleView from '../main/daysale/sale';
import GoodsSaleView from '../main/daysale/goods';

// 404
import NotFoundView from '../main/not-found';

const localRouterMap = {
    '/daysale/upload': <DaysaleUploadView />,
    '/daysale/sale-day': <SaleDayView />,
    '/daysale/sale': <SaleView />,
    '/daysale/goods': <GoodsSaleView />,
}


export default function RouteView() {
    const [items, setItems] = useState([])
        , { userMenu } = useContext(AppContext)

    useEffect(() => {
        if (userMenu.length > 0) {
            let record = []
            const formarItem = (data) => {
                data.forEach(item => {
                    item.type === 2 && record.push(item)
                    item.children && formarItem(item.children)
                })
            }
            formarItem(userMenu)
            setItems(record)
        }
    }, [userMenu])


    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            {
                items.map(item => {
                    return (
                        <Route
                            key={item.id}
                            exact
                            path={item.url}
                            element={localRouterMap[item.url]}
                        />
                    )
                })
            }
            <Route path="*" element={<NotFoundView />}></Route>
        </Routes>
    )
}
