import { createSlice } from "@reduxjs/toolkit";

const storageToken = sessionStorage.getItem("token")
    , tokenSlice = createSlice({
        name: 'token',
        initialState: { token: storageToken || null },
        reducers: {
            setToken(state, { payload }) {
                state.token = payload.token
                sessionStorage.setItem("token", payload.token)
            }
        }
    })

// 导出action函数
export const { setToken } = tokenSlice.actions


// 导出  reducer ， 创建store
export default tokenSlice.reducer