import React, { useMemo, useContext, useState } from 'react';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { AppContext } from '../../store/context';

function PageBreadcrumb({ type = 'title' }) {
    const { pathname } = useLocation()
        , { userMenu, theme: { layout } } = useContext(AppContext)
        , [title, setTitle] = useState('')

    const breadcrumbItems = useMemo(() => {
        const [, heading = null, subheading = null] = pathname.split("/")
        const items = [
            {
                title: (
                    <>
                        <HomeOutlined style={{ fontSize: 18 }} />
                        <Link to={'/'} >管理平台</Link>
                    </>
                )
            }
        ]

        if (heading) {
            const record = userMenu.find(item => item.url === pathname)
            if (record) {
                items.push({
                    title: record.name
                })
                setTitle(record.name)
                if (subheading && record.children) {
                    const row = record.children.find(item => item.url === pathname)
                    items.push({
                        title: row.name
                    })
                    setTitle(row.name)
                }
            }
        }

        return items
    }, [pathname, userMenu])

    return type === 'breadcrumb' || (layout === 'top' && type === 'title') ? (
        <Breadcrumb
            style={{
                padding: '5px 0',
                height: 32,
                fontSize: 16
            }}
            items={breadcrumbItems}
        />
    ) : title
}



export default PageBreadcrumb



