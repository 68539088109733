import React, { Component } from 'react';
import { Card, Col, Row, Tree, DatePicker, Space, Button, Descriptions, Badge, InputNumber, Empty, Spin, Upload } from 'antd';
import { LoadingOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../store/context';
import PageBreadcrumb from '../../../../components/breadcrumb';
import dayjs from 'dayjs';
import sys from '../../../../service/api/sys';

function regexHandleNum(num) {
    return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ' ,'); // 3是千分位，4是万分位
}

export default class DaysaleUpload extends Component {
    static contextType = AppContext
    state = {
        loading: false,
        queryParams: {
            day: null,
            username: null
        },
        storeTree: [],
        dataSource: false,
        formData: {}
    }

    // 首次加载数据
    componentDidMount() {
        this.getStoreList()
    }

    getStoreList = async () => {
        try {
            const { data } = await sys.apiUser.getStoreList()
            this.setState({
                storeTree: data.map(record => ({ key: record.username, title: record.name }))
            })
        } catch (error) {

        }
    }

    getSaleDay = async () => {
        const { queryParams } = this.state
        if (!queryParams.day) {
            this.context.notification.warning({
                message: '提示',
                placement: 'bottomRight',
                description: '请选择日期'
            })
            return
        }

        if (!queryParams.username) {
            this.context.notification.warning({
                message: '提示',
                placement: 'bottomRight',
                description: '请选择门店'
            })
            return
        }
        this.setState({
            loading: true
        })
        try {
            const { data } = await sys.apiUser.getSaleDay(queryParams)
                , { store, store_name, open_day, day, mtd, ytd, daily_sales, atv, flag, tr, coller_td, coller, truz_td, truz, other_td, other, nj_td, nj } = data
            this.setState({
                formData: data
            }, () => {
                this.setState({
                    loading: false,
                    dataSource: [
                        {
                            key: 1,
                            label: '店铺ID',
                            children: store
                        },
                        {
                            key: 2,
                            label: '店铺名称',
                            children: store_name
                        },
                        {
                            key: 3,
                            label: '开业日期',
                            children: open_day
                        },
                        {
                            key: 4,
                            label: '营业日期',
                            children: day
                        },
                        {
                            key: 5,
                            label: '月销售',
                            children: `${regexHandleNum(mtd)} 元`
                        },
                        {
                            key: 6,
                            label: '年销售',
                            children: `${regexHandleNum(ytd)} 元`
                        },
                        {
                            key: 7,
                            label: '当日销售',
                            children: `${regexHandleNum(daily_sales)} 元`
                        },
                        {
                            key: 8,
                            label: '客单价',
                            children: atv ? <Badge status="processing" text={`${regexHandleNum(atv)} 元`} /> : null
                        },
                        {
                            key: 9,
                            label: '客单数',
                            span: 2,
                            children: flag === 1 ?
                                (`${tr}`) : (
                                    <Space.Compact>
                                        <InputNumber
                                            style={{ width: 400 }}
                                            onChange={(value) => {
                                                this.setState({
                                                    formData: {
                                                        ...this.state.formData,
                                                        tr: value + '',
                                                        atv: (Number(daily_sales) / value).toFixed(2)
                                                    }
                                                })
                                            }}
                                        />
                                        <Button
                                            onClick={() => {
                                                const { formData } = this.state
                                                if (formData.tr) {
                                                    this.saveSaleDay(formData)
                                                }
                                            }}
                                        >
                                            保存
                                        </Button>
                                    </Space.Compact>
                                )
                        },
                        {
                            key: 10,
                            label: 'COLLER',
                            span: 2,
                            children: (
                                <Space>
                                    <p>销售额：{regexHandleNum(coller_td)} 元</p>
                                    <p>占比：{coller}</p>
                                </Space>
                            )
                        },
                        {
                            key: 11,
                            label: 'TRUZ',
                            span: 2,
                            children: (
                                <Space>
                                    <p>销售额：{regexHandleNum(truz_td)} 元</p>
                                    <p>占比：{truz}</p>
                                </Space>
                            )
                        },
                        {
                            key: 12,
                            label: 'BF&BT&Others',
                            span: 2,
                            children: (
                                <Space>
                                    <p>销售额：{regexHandleNum(other_td)} 元</p>
                                    <p>占比：{other}</p>
                                </Space>
                            )
                        },
                        {
                            key: 12,
                            label: 'NJ',
                            span: 2,
                            children: (
                                <Space>
                                    <p>销售额：{regexHandleNum(nj_td)} 元</p>
                                    <p>占比：{nj}</p>
                                </Space>
                            )
                        }
                    ]
                })
            })
        } catch (error) {
            this.setState({
                loading: false,
                formData: {},
                dataSource: false
            })
        }
    }

    // 选择树
    onSelect = ([username]) => {
        const { queryParams } = this.state
        this.setState({
            queryParams: {
                ...queryParams,
                username
            }
        }, () => {
            if (queryParams.day) {
                this.getSaleDay()
            }
        })
    }

    onChange = (e) => {
        const { queryParams } = this.state
            , day = dayjs(e).format('YYYY-MM-DD')
        this.setState({
            queryParams: {
                ...queryParams,
                day
            }
        })
    }

    saveSaleDay = async (queryParams) => {
        try {
            await sys.apiUser.saveSaleDay(queryParams)
            this.getSaleDay()
        } catch (error) {

        }
    }

    render() {
        const { storeTree, dataSource, queryParams: { day, username }, loading } = this.state
        return (
            <Card
                title={<PageBreadcrumb />}
                className='page-card-full'
                extra={(
                    <Space>
                        <Space.Compact>
                            <DatePicker
                                allowClear={false}
                                style={{ width: 348 }}
                                presets={[
                                    {
                                        label: '昨天',
                                        value: dayjs().add(-1, 'd'),
                                    },
                                    {
                                        label: '前天',
                                        value: dayjs().add(-2, 'd'),
                                    },
                                    {
                                        label: '一周前',
                                        value: dayjs().add(-7, 'd'),
                                    },
                                ]}
                                onChange={this.onChange}
                            />

                            <Button
                                type="primary"
                                disabled={!day || !username}
                                onClick={this.getSaleDay}
                            >
                                查询
                            </Button>
                        </Space.Compact>
                        <Upload
                            beforeUpload={async file => {
                                const { code, message } = await sys.apiUser.import(file)
                                // 导入成功
                                if (code === 0) {
                                    this.context.notification.success({
                                        message: '提示',
                                        placement: 'bottomRight',
                                        description: message
                                    })
                                } else {
                                    this.context.notification.error({
                                        message: '提示',
                                        placement: 'bottomRight',
                                        description: message
                                    })
                                }
                                return false
                            }}
                            maxCount={1}
                            name="files"
                            showUploadList={false}
                        >
                            <Button
                                icon={<CloudUploadOutlined />}
                                type='text'
                            >
                                上传营业数据
                            </Button>
                        </Upload>
                    </Space>
                )}
            >
                <Row>
                    <Col span={4}>
                        <Tree
                            treeData={storeTree}
                            onSelect={this.onSelect}
                            blockNode
                        />
                    </Col>
                    <Col span={20}>
                        <Spin
                            indicator={<LoadingOutlined style={{ fontSize: 64 }} spin />}
                            spinning={loading}
                            style={{ width: '100%', height: '100%' }}
                        >
                            {
                                dataSource ? (
                                    <Descriptions
                                        title={'统计信息'}
                                        items={dataSource}
                                        layout="vertical"
                                        bordered
                                        column={2}
                                    />
                                ) : (

                                    <Empty description="无销售记录" />
                                )
                            }
                        </Spin>
                    </Col>
                </Row>
            </Card>
        )
    }
}
