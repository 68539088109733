import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import { Provider } from 'react-redux';
import store from './store';
const root = createRoot(document.getElementById('root'));

console.log(
    '%c                   _oo0oo_                     \n' +
    '                  o8888888o										\n' +
    '                  88" . "88										\n' +
    '                  (| -_- |)										\n' +
    '                   O\\ = /O										\n' +
    "               ____/`---'\\____									\n" +
    "             .   ' \\\\| |// `.									\n" +
    '              / \\\\||| : |||// \\								\n' +
    '           / _||||| -卍- |||||_ \\								\n' +
    '              | | \\\\\\ - /// | |								\n' +
    "            | \\_| ''\\---/'' | |								\n" +
    '             \\ .-\\__ `-` ___/-. /							\n' +
    "          ___`. .' /--.--\\ `. . __							\n" +
    '       ."" "< `.___\\_<|>_/___. ` >" "".        \n' +
    '      | | : `- \\`.;`\\ _ /`;.`/ - ` : | |       \n' +
    '        \\ \\ `-. \\_ __\\ /__ _/ .-` / /          \n' +
    "======`-.____`-.___\\_____/___.-`____.-'======  \n" +
    "                   `=---='                     \n" +
    '.............................................  \n\t\t' +
    '佛祖镇楼                  BUG辟易                          \n'
    , "color:#fff; font-size:14px")

root.render(
    <Provider store={store}>
        <App />
    </Provider>
)