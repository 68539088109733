import React, { useContext, useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from '@ant-design/icons';
import { AppContext } from '../../../store/context';

export default function LeftNav(props) {
    const navigate = useNavigate()
        , [items, setItems] = useState([])
        , [defaultSelectedKeys, setDefaultSelectedKeys] = useState([])
        , [selectedKeys, setSelectedKeys] = useState([])
        , [isInit, setInit] = useState(false)
        , [openKeys, setOpenKeys] = useState([])
        , { pathname } = useLocation()
        , { userMenu } = useContext(AppContext)


    const getItem = (label, key, icon, children, type, disabled, role) => {
        return {
            key,
            icon,
            children,
            label,
            type,
            disabled,
            role
        }
    }

    useEffect(() => {
        if (userMenu && userMenu.length > 0) {
            // 递归渲染菜单
            const formarItem = (data) => {
                const record = []
                data.forEach(item => {
                    const { url, name, icon, type, children } = item
                        , Children = children && type === 1 ? formarItem(children) : null
                    record.push(getItem(name, url, icon ? renderIcon(icon) : null, Children, type, false, item.per))
                })
                return record
            }

            setItems(formarItem(userMenu))

            userMenu.forEach(row => {
                if (isInit) return
                if (row.children && row.children.length > 0) {
                    row.children.forEach(item => {
                        if (item.url === pathname) {
                            setInit(true)
                            setOpenKeys([row.url])
                            setDefaultSelectedKeys([pathname])
                            setSelectedKeys([pathname])
                        }
                    })

                } else {
                    if (row.url.split("/")[1] === pathname.split("/")[1]) {
                        setInit(true)
                        setOpenKeys([pathname])
                        setDefaultSelectedKeys([pathname])
                        setSelectedKeys([pathname])
                    }
                }
            })
            if (pathname === '/') {
                setOpenKeys([])
                setSelectedKeys([])
            }
            setInit(true)
        }
    }, [userMenu, pathname, isInit])



    // 渲染图标
    const renderIcon = (name) => {
        return React.createElement(Icon[name])
    }


    const onSelect = (e) => {
        const { domEvent, item, key } = e
        setSelectedKeys([key])
        navigate(key, { state: { title: domEvent.currentTarget.innerText, role: item.props.role } })
    }


    // 获取点击菜单路径
    const findMenuKey = (menus, func, path = []) => {
        if (!menus) return []
        for (const data of menus) {
            path.push(data.key)
            if (func(data)) return path
            if (data.children) {
                const findChildren = findMenuKey(data.children, func, path)
                if (findChildren.length) return findChildren
            }
            path.pop()
        }
        return []
    }

    // 多级菜单时，点击菜单，收起其他菜单
    const onOpenChange = (openKeys) => {
        const latestOpenKey = [...openKeys].pop()
        const key = findMenuKey(items, (data) => data.key === latestOpenKey)
        setOpenKeys(key)
    }

    return isInit ? (
        <Menu
            mode={props.mode}
            defaultSelectedKeys={defaultSelectedKeys}
            items={items}
            onSelect={onSelect}
            onOpenChange={onOpenChange}
            openKeys={openKeys} // 当前展开的 SubMenu 菜单项 key 数组
            selectedKeys={selectedKeys}
            style={{ borderInlineEnd: 'none' }}
        />
    ) : null
}