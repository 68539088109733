import { createContext } from 'react';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const initialState = JSON.parse(localStorage.getItem('app')) || {
    user: {
        imageUrl: '',
        name: ''
    },
   
    userMenu: [],
    collapsed: false,
    theme: {
        layout: 'blend',
        contentTheme: 'light',
        skin: 'dark',
        themeColor: 'rgb(24, 144, 255)'
    },
}

export const AppContext = createContext(initialState);

const AppContextAtom = atomWithStorage('app', initialState)

export function useAppContextAtom() {
    return useAtom(AppContextAtom)
}