import React, { Component } from 'react';
import { Card } from 'antd';
import { Column } from '@ant-design/plots';
import sys from '../../../service/api/sys';

export default class HomeView extends Component {
	state = {
		loading: false,
		title: '',
		type: '',
		config: false
	}

	// 首次加载数据
	componentDidMount() {
		this.fetch()
	}

	fetch = async () => {
		const { data: { title, config, type } } = await sys.apiUser.getWeek()
		this.setState({
			title,
			type,
			config,
		})
	}

	render() {
		const { title, config } = this.state
		return (
			<Card
				title={title}
				className='page-card-full'
				bodyStyle={{ height: 'calc(100vh - 64px - 32px - 67px - 56px)' }}
			>
				{
					config && <Column {...config} />
				}
			</Card>
		)
	}
}
