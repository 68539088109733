import React from 'react';
import { Tooltip, Button, Popover, Tree } from 'antd';
import { FilterOutlined } from '@ant-design/icons';

export default function DropdownColumns(props) {
    const { columns, checkedKeys = [] } = props
        , treeData = columns.map(({ title, dataIndex, disabled = false }) => ({
            title: title,
            key: dataIndex,
            disabled: disabled ? true : typeof title === 'string' ? false : true
        }))
    return (
        <Popover
            title='列设置'
            placement="bottomRight"
            content={
                <>
                    <Tree
                        checkable
                        blockNode
                        multiple
                        treeData={treeData}
                        rootStyle={{ backgroundColor: 'transparent' }}
                        className="dropdown-columns-tree"
                        onCheck={(checkedKeys) => props.onChange(checkedKeys)}
                        checkedKeys={checkedKeys}
                        onSelect={(checkedKeys) => props.onChange(checkedKeys)}
                        selectedKeys={checkedKeys}
                    />
                </>
            }
            trigger="click"
            style={{ margin: 0 }}
        >
            <Tooltip title='列设置'>
                <Button
                    type='text'
                    icon={<FilterOutlined />}
                />
            </Tooltip>
        </Popover>
    )
}
