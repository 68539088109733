import React, { useState } from 'react';
import { ConfigProvider, theme, Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import RouteView from './route';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import './App.scss';
import { AppContext, useAppContextAtom } from './store/context';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

function AppView() {
    const [spinning, setSpinning] = useState(false)
        , [api, contextHolder] = notification.useNotification()
        , [App, setApppContext] = useAppContextAtom()

 
    


    

    


    return (
        <ConfigProvider
            locale={zhCN}
            theme={{
                algorithm: App.theme.skin === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm,
                token: {
                    colorPrimary: App.theme.themeColor,
                    screenXXLMin: 2000, // for grid (row/col)
                    screenXXL: 2000 // default is 1600, for List
                },
            }}
        >
            <AppContext.Provider value={{
                ...App,
                notification: api
            }}>
                {contextHolder}
                <Spin
                    indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                    spinning={spinning}
                >
                    <RouteView />
                </Spin>
            </AppContext.Provider>
        </ConfigProvider>
    )
}

export default AppView