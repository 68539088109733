import React, { useContext, useState } from 'react';
import { Form, Input, Space, Button, Drawer } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { sys } from '../../../service';
import { AppContext } from '../../../store/context';

export default function SetPasswordDrawer(props) {
    const { open } = props
        , [form] = Form.useForm()
        , title = '设置密码'
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态
        , { notification } = useContext(AppContext)
    // 关闭
    const onClose = () => {
        props.onClose()
        setLoading(false)
        form.resetFields()
    }

    // 提交表单 => 区分编辑与新增提交
    const onFinish = async () => {
        setLoading(true)
        try {
            const queryParams = await form.validateFields()
            const { message } = await sys.apiUser.changePassword({ ...queryParams })
            notification.success({
                message: '提示',
                description: `${title}操作成功!`,
                placement: 'bottomRight'
            })
            onClose()
        } catch (error) {
            setLoading(false)
        }
    }


    return (
        <Drawer
            open={open}
            title={title}
            onClose={onClose}
            closable={false}
            extra={
                <Space.Compact>
                    <Button icon={<CloseOutlined />} onClick={onClose}>取消</Button>
                    <Button
                        icon={<CheckOutlined />}
                        type="primary"
                        onClick={() => form.submit()}
                        loading={loading}
                    >
                        保存
                    </Button>
                </Space.Compact>
            }
        >
            <Form
                form={form}
                layout="vertical"
                name="user-form"
                onFinish={onFinish}
            >
                <Form.Item
                    name="old_pwd"
                    label="原密码"
                    rules={[{ required: true, message: '原密码不能为空' }]}
                >
                    <Input type='password' placeholder="请填写原密码" />
                </Form.Item>

                <Form.Item
                    name="new_pwd"
                    label="新密码"
                    rules={[{ required: true, message: '新密码不能为空' }]}
                >
                    <Input type='password' placeholder="请填写新密码" />
                </Form.Item>

                <Form.Item
                    name="again_pwd"
                    label="确定密码"
                    rules={[{ required: true, message: '确定密码不能为空' }]}
                >
                    <Input type='password' placeholder="请填写确定密码" />
                </Form.Item>
            </Form>
        </Drawer>
    )
}
