import React, { useContext } from 'react';
import { Button, Tooltip, Space, theme } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { AppContext, useAppContextAtom } from '../../../store/context';

const { useToken } = theme;
export default function Collapsed() {
    const [, setApppContext] = useAppContextAtom()
        , { collapsed } = useContext(AppContext)
        , { token } = useToken()

    // 右侧菜单切换
    const toggleCollapsed = () => {
        setApppContext((state) => {
            return {
                ...state,
                collapsed: !state.collapsed
            }
        })
    }

    return (
        <Space
            style={{
                width: '100%',
                height: 49,
                borderTop: `1px solid ${token.colorBorderSecondary}`
            }}
        >
            <Tooltip
                title={collapsed ? '最小化导航栏' : '最大化导航栏'}
            >
                <Button
                    onClick={toggleCollapsed}
                    icon={collapsed ? (<MenuUnfoldOutlined />) : (<MenuFoldOutlined />)}
                    type="text"
                    style={{
                        width: 56,
                        margin: 4
                    }}
                />
            </Tooltip>
        </Space>
    )
}
