import React, { useEffect, useState } from 'react';
import { Form, Input, Space, Button, Drawer, Spin, Radio } from 'antd';
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { AppContext, useAppContextAtom } from '../../../store/context';
import { sys } from '../../../service';

export default function ConfigDrawer(props) {
    const { id, open } = props
        , { notification } = React.useContext(AppContext)
        , [, setApppContext] = useAppContextAtom()
        , [form] = Form.useForm()
        , title = '信息设置'
        , [spinning, setSpinning] = useState(false)     // 设置获取数据载入状态
        , [loading, setLoading] = useState(false)       // 设置按钮载入状态

    // 配置
    useEffect(() => {
        function fetchData() {
            if (open) {
                setSpinning(true)
                sys.apiUser.getUserInfo().then(({ data }) => {
                    form.setFieldsValue(data)
                })
                setSpinning(false)
            }
        }
        fetchData()
    }, [id, open, form])

    // 关闭窗口 => 清空表单数据
    const onClose = () => {
        props.onClose()
        setSpinning(false)
        setLoading(false)
        form.resetFields()
    }

    // 提交表单 => 区分编辑与新增提交
    const onFinish = async () => {
        setLoading(true)
        const queryParams = await form.validateFields()
        try {
            await sys.apiUser.putUserInfo(queryParams)
            setApppContext((state => {
                return {
                    ...state,
                    site: queryParams
                }
            }))
            notification.info({
                message: '提示',
                description: `${title}操作成功!`,
                placement: 'bottomRight'
            })
            onClose()
        } catch (error) {
            setLoading(false)
        }
    }



    return (
        <Drawer
            open={open}
            title={title}
            onClose={onClose}
            closable={false}
            extra={
                <Space.Compact>
                    <Button icon={<CloseOutlined />} onClick={onClose}>取消</Button>
                    <Button
                        icon={<CheckOutlined />}
                        type="primary"
                        onClick={() => form.submit()}
                        loading={loading}
                    >
                        保存
                    </Button>
                </Space.Compact>
            }
            size="large"
        >
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 36 }} />}
                spinning={spinning}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="user-form"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        label="店铺ID"
                    >
                        <Input disabled placeholder="店铺ID" />
                    </Form.Item>

                    <Form.Item
                        name="name"
                        label="店铺名称"
                    >
                        <Input placeholder="店铺名称" />
                    </Form.Item>


                    <Form.Item
                        name="city"
                        label="城市"
                    >
                        <Input placeholder="城市" />
                    </Form.Item>

                    <Form.Item
                        name="principal"
                        label="代理商"
                    >
                        <Input placeholder="代理商" />
                    </Form.Item>

                    <Form.Item
                        name="telephone"
                        label="联系电话"
                    >
                        <Input placeholder="联系电话" />
                    </Form.Item>

                    <Form.Item
                        name="is_open"
                        label="营业状态"
                    >
                        <Radio.Group>
                            <Radio.Button value={0}>暂停营业</Radio.Button>
                            <Radio.Button value={1}>营业中</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        name="id"
                        hidden={true}
                    >
                        <Input />
                    </Form.Item>

                </Form>
            </Spin>
        </Drawer>
    )
}
