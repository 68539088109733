import axios from 'axios';
import { createBrowserHistory } from 'history';
import { notification } from 'antd';
import API from './config';
import { downloadFile } from '../utils/utils';


let history = createBrowserHistory();

//设置axios基础路径
const request = axios.create({
    baseURL: API,
    timeout: 1000 * 300,
})
// 请求拦截器
request.interceptors.request.use(config => {
    
    const token = sessionStorage.getItem('token')
    if (token && config.url !== '/v1/sys/login') {
        config.headers['token'] = token
    }
    return config
}, error => {
    return error;
})

// 响应拦截器
request.interceptors.response.use(
    response => {
        const code = Number(response.data.code)
        if (response.status === 200) {
            if (code === 0 || response.headers['content-type'] === 'application/octet-stream')
                return response.data
        }
        notification.open({
            message: '提示',
            description: response.data.message
        })
        return Promise.reject(response.data)
    },
    error => {
        if (error.response?.status === 401 || error.response?.status === 403) {
            sessionStorage.removeItem("token")
            history.push('/login')
            history.go()
            return Promise.reject(error)
        } else if (error.response?.status === 429) {
            notification.open({
                message: '提示',
                description: '访问太频繁，请稍后再试!'
            })
            return Promise.reject(error)
        }
        notification.open({
            message: '提示',
            description: error.message
        })
        return Promise.reject(error)
    }
)

/**
 * 下载配置
 * @param {*} url 
 * @param {*} filename
 * @param {*} suffix
 * @returns 
 */
request.download = async (url, filename, suffix) => {
    return request({
        url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => downloadFile(response, filename, suffix))
};

/**
 * 配置上传
 * @param {*} url 
 * @param {*} param1 
 * @param {*} fileName 
 * @returns 
 */
request.uploadFile = (url, { file }, fileName = 'file') => {
    let formData = new FormData();
    formData.append(fileName, file)
    return request({
        url,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        transformRequest: data => data,
        method: 'POST',
        data: formData
    })
}

// 最后把封装好的axios导出
export default request