import React, { useContext, useState } from 'react';
import { Dropdown, Space, Button, Avatar } from 'antd';
import { LogoutOutlined, UnlockOutlined, DesktopOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../store/context';
import SetPassword from './set-password';

import Config from './set-config';


import './index.scss';
export default function Tool() {
    const navigate = useNavigate()
        , [openPassword, setPassword] = useState(false)
        , [openConfig, setConfig] = useState(false)
        , { notification } = useContext(AppContext)

    // 下拉菜单配置
    const items = [
        {
            key: '1',
            label: '设置密码',
            icon: <UnlockOutlined />,
            onClick: () => {
                setPassword(true)
            }
        },
        {
            key: '2',
            label: '信息设置',
            icon: <DesktopOutlined />,
            onClick: () => {
                setConfig(true)
            }
        },
        {
            type: 'divider',
        },
        {
            key: '4',
            label: '退出登录',
            icon: <LogoutOutlined />,
            onClick: () => {
                sessionStorage.removeItem('token')
                notification.success({
                    message: '提示',
                    description: `退出系统成功！`,
                    placement: 'bottomRight'
                });
                navigate('/login', { replace: true })
            }
        }
    ]


    return (
        <>
            <Space style={{ display: 'flex', justifyContent: 'end' }}>

                <Dropdown
                    menu={{
                        items,
                        selectable: true,
                        selectedKeys: []
                    }}
                    placement="bottomRight"
                    trigger='click'
                    overlayStyle={{ minWidth: 160 }}
                    arrow
                >
                    <Button
                        type='text'
                    >
                        <Avatar
                            src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1"
                            style={{
                                width: 26,
                                height: 26,
                                marginTop: -4,
                                marginRight: 10,
                                backgroundColor: '#8c8c8c'
                            }}
                        />
                    </Button>
                </Dropdown>


            </Space>

            <SetPassword
                open={openPassword}
                onClose={() => setPassword(false)}
            />

            <Config
                open={openConfig}
                onClose={() => setConfig(false)}
            />
        </>
    )
}
