import request from "../request"
/**
 * 系统接口
 */
// const Authorization = 'Basic eW5zem55LWFkbWluOjEyMzQ1Ng==';

/**
 * 登陆
 * @param {*} username 
 * @param {*} password 
 * @returns 
 */
const userLogin = (username, password) => request.post('/v1/sys/login', { username, password })


/**
 * 用户管理
 */
const apiUser = {
    // 设置登录用户密码
    changePassword: (queryParams) => request.put('/v1/sys/user/change/pwd', queryParams),
    // 获取当前用户信息
    getUserInfo: () => request.post('/v1/sys/user/info'),
    // 编辑当前用户信息
    putUserInfo: (queryParams) => request.put('/v1/sys/user', queryParams),
    // 获取该用户下所有店铺列表
    getStoreList: () => request.get('/v1/sys/store/shop'),
    // 获取日报
    getSaleDay: (queryParams) => request.post('/v1/sale/day', queryParams),
    // 保存日报
    saveSaleDay: (queryParams) => request.post('/v1/sale/day/report', queryParams),
    // 导入
    import: (file) => request.uploadFile('/v1/sale/import', { file }, 'files'),
    // 一周门店销售情况
    getWeek: () => request.get('/v1/sale/week/report'),
    // 导出
    daydown: (url) => request.download(url,  'result', '.xlsx'),
}

const getSaleDay = (queryParams) => request.post('/v2/sale/daily/report', queryParams)
const getSale = (queryParams) => request.post('/v2/sale/daily/sale', queryParams)
const getGoodsSale = (queryParams) => request.post('/v2/sale/goods/sale', queryParams)

const sys = {
    userLogin,
    apiUser,
    getSaleDay,
    getSale,
    getGoodsSale
}

export default sys