import React, { useContext } from 'react';
import { Space, Drawer, Tooltip, Typography, Switch, Divider } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { AppContext, useAppContextAtom } from '../../../store/context';


export default function SetThemeDrawer(props) {
    const { open } = props
        , [, setApppContext] = useAppContextAtom()
        , { theme: { skin, layout, contentTheme, themeColor } } = useContext(AppContext)
        , themeColorData = [
            {
                label: '拂晓蓝（默认）',
                value: 'rgb(24, 144, 255)'
            },
            {
                label: '薄暮',
                value: 'rgb(245, 34, 45)'
            },
            {
                label: '火山',
                value: 'rgb(250, 84, 28)'
            },
            {
                label: '日暮',
                value: 'rgb(250, 173, 20)'
            },
            {
                label: '明青',
                value: 'rgb(19, 194, 194)'
            },
            {
                label: '极光绿',
                value: 'rgb(82, 196, 26)'
            },
            {
                label: '极客蓝',
                value: 'rgb(47, 84, 235)'
            },
            {
                label: '酱紫',
                value: 'rgb(114, 46, 209)'
            }
        ]


    const onChange = (queryParams) => {
        setApppContext((state) => {
            return {
                ...state,
                theme: {
                    ...state.theme,
                    ...queryParams
                }
            }
        })
    }


    return (
        <Drawer
            title="显示设置"
            placement="right"
            onClose={() => props.onClose()}
            open={open}
        >
            <Space
                size="large"
                direction="vertical"
            >
                <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography.Text>暗黑主题</Typography.Text>
                    <Switch
                        checked={skin === 'dark'}
                        onChange={() => {
                            setApppContext((state) => {
                                return {
                                    ...state,
                                    theme: {
                                        ...state.theme,
                                        skin: skin === 'light' ? 'dark' : 'light',
                                        contentTheme: skin === 'light' ? 'dark' : 'light'
                                    }
                                }
                            })
                        }}
                    />
                </Space>

                <Divider style={{ margin: '6px 0' }} />

                {
                    skin === 'dark' ? (
                        <>
                            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography.Text>内容区域亮色</Typography.Text>
                                <Switch
                                    checked={contentTheme === 'light'}
                                    onChange={() => {
                                        setApppContext((state) => {
                                            return {
                                                ...state,
                                                theme: {
                                                    ...state.theme,
                                                    contentTheme: contentTheme === 'light' ? 'dark' : 'light',
                                                }
                                            }
                                        })
                                    }}
                                />
                            </Space>

                            <Divider style={{ margin: '6px 0' }} />
                        </>
                    ) : null
                }

                <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography.Text>页面布局</Typography.Text>
                </Space>
                <Space size="middle" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Space
                        direction='vertical'
                        onClick={() => onChange({ layout: 'sider' })}
                    >
                        <div
                            className='layout-sider'
                            style={{ '--borderRadius': `8px` }}
                        >
                            {
                                layout === 'sider' ? (
                                    <CheckOutlined
                                        style={{
                                            position: 'absolute',
                                            right: 6,
                                            bottom: 4
                                        }}
                                    />
                                ) : null
                            }
                        </div>
                        <Space style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography.Text>侧边菜单布局</Typography.Text>
                        </Space>
                    </Space>

                    <Space
                        direction='vertical'
                        onClick={() => onChange({ layout: 'top' })}
                    >
                        <div
                            className='layout-top'
                            style={{ '--borderRadius': `8px` }}
                        >
                            {
                                layout === 'top' ? (
                                    <CheckOutlined
                                        style={{
                                            position: 'absolute',
                                            right: 6,
                                            bottom: 4
                                        }}
                                    />
                                ) : null
                            }
                        </div>
                        <Space style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography.Text>顶部菜单布局</Typography.Text>
                        </Space>
                    </Space>

                    <Space
                        direction='vertical'
                        onClick={() => onChange({ layout: 'blend' })}
                    >
                        <div
                            className='layout-blend'
                            style={{ '--borderRadius': `8px` }}
                        >
                            {
                                layout === 'blend' ? (
                                    <CheckOutlined
                                        style={{
                                            position: 'absolute',
                                            right: 6,
                                            bottom: 4
                                        }}
                                    />
                                ) : null
                            }
                        </div>
                        <Space style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography.Text>混合菜单布局</Typography.Text>
                        </Space>
                    </Space>
                </Space>

                <Divider style={{ margin: '6px 0' }} />

                <Space>
                    <Typography.Text>主题色</Typography.Text>
                </Space>

                <Space>
                    {
                        themeColorData.map(item => (
                            <Tooltip
                                key={item.value}
                                title={item.label}
                            >
                                <div
                                    onClick={() => onChange({ themeColor: item.value })}
                                    style={{ '--bgColor': item.value, '--borderRadius': `8px` }}
                                    className='color-block'
                                >
                                    {
                                        themeColor === item.value ? <CheckOutlined /> : null
                                    }
                                </div>
                            </Tooltip>
                        ))
                    }
                </Space>
            </Space>
        </Drawer>
    )
}
