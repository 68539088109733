import React, { Component } from 'react';
import { Card, Space, Tooltip, Button, Table, Divider, DatePicker, Select } from 'antd';
import { Loading3QuartersOutlined, ExportOutlined,CheckOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../store/context';
import DropdownColumns from '../../../../components/dropdown-columns';
import dayjs from 'dayjs';
import { PAGINATION } from '../../../../utils/constants';
// import PageBreadcrumb from '../../../../components/breadcrumb';
import { sys } from '../../../../service';

function regexHandleNum(num) {
    return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ' ,'); // 3是千分位，4是万分位
}


export default class SaleDayView extends Component {
    static contextType = AppContext
    state = {
        queryParams: {
            start_day: dayjs(),
            end_day: dayjs(),
            store: null
        },
        storeTree: [],
        columnsKey: ['store', 'store_name', 'open_day', 'day', 'mtd', 'ytd', 'daily_sales', 'atv', 'tr', 'coller_td', 'truz_td', 'other_td','nj_td'],
        columns: [
            {
                title: '店铺ID',
                dataIndex: 'store'
            },
            {
                title: '店铺名称',
                dataIndex: 'store_name'
            },
            {
                title: '开业日期',
                dataIndex: 'open_day'
            },
            {
                title: '营业日期',
                dataIndex: 'day'
            },
            {
                title: '月销售',
                dataIndex: 'mtd',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '年销售',
                dataIndex: 'ytd',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '当日销售',
                dataIndex: 'daily_sales',
                render: (v) => regexHandleNum(v)
            },
            {
                title: '客单价',
                dataIndex: 'atv'
            },
            {
                title: '客单数',
                dataIndex: 'tr'
            },
            {
                title: 'COLLER',
                dataIndex: 'coller_td',
                render: (_, { coller_td, coller }) => (
                    <Space>
                        <p>{regexHandleNum(coller_td)} 元</p>
                        <p>{coller}</p>
                    </Space>
                )
            },
            {
                title: 'TRUZ',
                dataIndex: 'truz_td',
                render: (_, { truz_td, truz }) => (
                    <Space>
                        <p>{regexHandleNum(truz_td)} 元</p>
                        <p>{truz}</p>
                    </Space>
                )
            },
            {
                title: 'BF&BT&Others',
                dataIndex: 'other_td',
                render: (_, { other_td, other }) => (
                    <Space>
                        <p>{regexHandleNum(other_td)} 元</p>
                        <p>{other}</p>
                    </Space>
                )
            },
            {
                title: 'NJ',
                dataIndex: 'nj_td',
                render: (_, { nj_td, nj }) => (
                    <Space>
                        <p>{regexHandleNum(nj_td)} 元</p>
                        <p>{nj}</p>
                    </Space>
                )
            }
        ],
        dataSource: [],
        id: null,
        open: false,
        loading: false,
        pagination: PAGINATION
    }

 


    // 首次加载数据
    componentDidMount() {
        this.getStoreList()
        this.fetch()
    }

    getStoreList = async () => {
        try {
            const { data } = await sys.apiUser.getStoreList()
            this.setState({
                storeTree: data.map(record => ({ value: record.username, label: record.name }))
            })
        } catch (error) {

        }
    }

    // 获取列表数据
    fetch = async (params = {  pagination: PAGINATION }) => {
        this.setState({ loading: true })
        const { current, pageSize } = params.pagination
            , { queryParams: { start_day, end_day, store } } = this.state
            , { data:{total, records} } = await sys.getSaleDay({ store, start_day: dayjs(start_day).format('YYYY-MM-DD'), end_day: dayjs(end_day).format('YYYY-MM-DD'), current, pageSize })
        this.setState({
            loading: false,
            dataSource: records,
            pagination: {
                ...PAGINATION,
                current,
                pageSize,
                total: total
            }
        })
    }

    // 导出
    handleExport = async () => {
        const { start_day, end_day, store } = this.state.queryParams
        await sys.apiUser.daydown(`/v1/sale/day/down?store=${store ? store : ''}&start_day=${dayjs(start_day).format('YYYY-MM-DD')}&end_day=${dayjs(end_day).format('YYYY-MM-DD')}`)
    }



    render() {
        const { dataSource, columns, loading, columnsKey, storeTree, queryParams: { start_day, end_day } , pagination} = this.state
        const { fetch } = this
        return (
            <>
                <Card
                    title={"日报列表"}
                    className='page-card-full'
                    extra={
                        <Space
                            split={<Divider type="vertical" />}
                        >
                            <Select
                                options={storeTree}
                                style={{ width: 300 }}
                                placeholder="请选择门店"
                                onChange={(value) => {
                                    this.setState({
                                        queryParams: {
                                            ...this.state.queryParams,
                                            store: value
                                        }
                                    }, () => {
                                        fetch()
                                    })
                                }}
                            >

                            </Select>

                            <DatePicker.RangePicker
                                defaultValue={[start_day, end_day]}
                                allowClear={false}
                                onChange={([start_day, end_day]) => {
                                    this.setState({
                                        queryParams: {
                                            ...this.state.queryParams,
                                            start_day,
                                            end_day
                                        }
                                    })
                                }}
                            />

                            <Button 
                                icon={<CheckOutlined />}
                                onClick={() => {
                                    fetch()
                                }}
                            >
                                查询
                            </Button>
                            
                            <Tooltip title='刷新'>
                                <Button
                                    type='text'
                                    icon={<Loading3QuartersOutlined />}
                                    onClick={() => fetch()}
                                />
                            </Tooltip>

                            <DropdownColumns
                                columns={columns}
                                checkedKeys={columnsKey}
                                onChange={columnsKey => this.setState({ columnsKey })}
                            />

                            <Button
                                icon={<ExportOutlined />}
                                disabled={true}
                                onClick={this.handleExport}
                                type="text"
                            >
                                导出
                            </Button>
                        </Space>
                    }
                >
                    <Table
                        columns={columns.filter(record => columnsKey.includes(record.dataIndex))}
                        rowKey={record => record.id}
                        dataSource={dataSource}
                        pagination={{
                            ...pagination,
                            showSizeChanger: true
                        }}
                        onChange={(pagination) => fetch({ pagination })}
                        loading={loading}
                        virtual
                        scroll={{  y: window.innerHeight - 310}}
                    />
                </Card>
            </>
        )
    }
}