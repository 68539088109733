import React, { useContext } from 'react';
import { Image } from 'antd';
import { AppContext } from '../../../store/context';
import MinLogo from './min-logo.png';

export default function Logo() {
    const {  collapsed, theme: { layout } } = useContext(AppContext)
    return (
        <div
            style={{
                width: layout === 'sider' ? collapsed ? 64 : 199 : 179,
                height: layout === 'sider' ? 64 : 64,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Image
                src={MinLogo}
                style={{
                    width: '100%',
                    padding: collapsed && layout === 'sider' ? '5px' : '15px 10px'
                }}
                preview={false}
            />
        </div>
    )
}
